/* components/Navbar.css */
.maindiv{
    background-color: #050000;
  }
  nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: #000000;
      color: white;
    }
    .logo{
      display: flex; /* Add display: flex to the logo container */
      align-items: center; 
    }
    .logo img {
      width: 150px;
      height: 50px;
      margin-right: 0.5rem;
    }
    
    .navbar-links {
      display: flex;
      gap: 1rem;
    }
  
    .auth-options {
      display: flex;
      gap: 1rem;
    }
  
    .auth-options a{
      color: white;
      text-decoration: none;
    }
    
    .navbar-links a {
      color: white;
      text-decoration: none;
    }
    
    .hamburger {
      font-size: 1.5rem;
      cursor: pointer;
      margin-left: 10px; /* Add the unit (e.g., px) */
    }
  
    .hamburger:hover + .dropdown {
      display: flex;
      flex-direction: column;
    }
    
  /* Show the dropdown when hovering over the parent container */
  .navbar:hover .dropdown {
    display: flex;
    flex-direction: column;
  }
    
    .dropdown {
      display: none;
      position: absolute;
      top: 10%;
      left: 15%;
      display: flex;
      flex-direction: column;
      background-color: #241616;
      color: white;
      border: 1px solid #fff5f5;
      padding: 0.5rem;
      z-index: 1;
    }
    
    .dropdown a {
      color: white;
      text-decoration: none;
    }
  
    /* components/Navbar.css */
  .banner {
    position: relative;
    width: 100%;
    overflow: hidden;
    
  }
  
  .banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
  .signupbanner-content {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    width: 100%;
    margin-left: 20px;
    margin-top: 180px;
  }
  .signupbanner-content img{
    width: 100px;
  }
  
  .signupbanner-content h1 {
    font-size: 1.5rem;
  }
  
  .signupbanner-content p {
    font-size: 1.2rem;
  }
  .banner-contentnext{
    position: absolute;
    top: 75%;
    left: 20%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    width: 500px;
    text-align: justify;
  }
  
  .banner-contentright{
    position: absolute;
    top: 75%;
    left: 95%;
    transform: translate(-50%, -50%);
    text-align: right;
    color: white;
    width: 500px;
    text-align: justify;
  }
  
  /* components/Navbar.css */
  
  .signup-section {
    top: 10%;
    background-color: #050000;
  
  }
  
  .signup-section h2 {
    color: #333;
  }
  
  .signup-buttons {
    display: flex;
    gap: 1rem;
    margin-left: 5%;
    top: 200px;
    padding: 30px;
  }
  
  .signup-button {
    padding: 0.8rem 4rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #faf62a;
    color: rgb(7, 7, 7);
    border: none;
    border-radius: 4px;
  }
  
 
  
  .crypto-business-section {
    padding: 2rem;
    background-color: #000000;
  }
  
  .column-container {
    display: flex;
    flex-wrap: wrap; /* Allow columns to wrap to the next row */
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .column-first {
    flex: 0 0 calc(20% - 2rem); /* Set width to 20% with some margin between columns */
    margin-right: 0.5rem;
    background-color: #000000;
    padding: 0.5rem;
    border: 1px solid #000000;
    border-radius: 4px;
    color: antiquewhite;
    margin-bottom: 1rem; /* Add some margin at the bottom for spacing */
  }
  
  .column {
    flex: 0 0 calc(20% - 2rem); /* Set width to 20% with some margin between columns */
    margin-right: 0.5rem;
    background-color: #000000;
    padding: 0.5rem;
    border: 1px solid #000000;
    border-radius: 4px;
    color: antiquewhite;
    margin-bottom: 1rem; /* Add some margin at the bottom for spacing */
  }
  
  
  /* Responsive styles for the signup section */
  @media screen and (max-width: 768px) {
    .signup-section {
      padding: 1rem;
    }
  
    .signup-buttons {
      flex-direction: column;
    }
  }
  
  
  /* Responsive styles for mobile screens */
  @media screen and (max-width: 768px) {
    .navbar {
      flex-direction: column; /* Stack items in a column on small screens */
    }
  
    .navbar-links,
    .auth-options {
      flex-direction: row; /* Display links in a row on larger screens */
    }
  
    .hamburger {
      margin-left: auto; /* Move the hamburger icon to the right on small screens */
    }
  
    .dropdown {
      position: static; /* Change position to static on small screens */
      display: none; /* Hide the dropdown by default on small screens */
      width: 100%; /* Full width on small screens */
    }
  
    .hamburger:hover + .dropdown,
    .navbar:hover .dropdown {
      display: flex; /* Show the dropdown when hovering on small screens */
    }
  
    /* Additional mobile styles go here */
  }

  
  
  
  .right-paragraph{
    margin: 0;
    margin-top: 20px;
    background: rgba(92, 77, 77, 0.5); /* Adjust the RGBA values and transparency as needed */
    padding: 10px; /* Add padding for better visibility */
    color: white; /* Set text color to white */
    font-size: 20chpx; /* Set the font size as needed */
  
  }
  
  .full-width-paragraph {
    background-color: rgb(5, 5, 83); /* Set the background color to blue */
    color: white; /* Set the text color to white */
    padding: 20px; /* Add padding for better visibility */
    text-align: center; /* Center-align the text */
    font-size: 120chpx;
  }
  
  .full-width-paragraph h2 {
    margin: 10px 0; /* Add margin between lines */
   
  }
  .full-width-paragraph h1 {
    margin: 10px 0; /* Add margin between lines */
    font-size: 120chpx;
  }
  
  .two-column-section {
    display: flex;
    justify-content: space-between;
    background-color: #fff5f5;
  }
  
  .two-columnleft-column p {
    text-align: justify;
    margin: 50px;
    line-height: 2.0;
  }
  
  .two-columnleft-column {
    width: 70%; /* Adjust the width as needed */
  }
  
  .two-columnimage-row {
    display: flex;
    margin: 40px;
    gap: 10px; /* Adjust the gap between images */
  }
  
  .two-columnimage-row img {
    width: 200px; /* Adjust the width of each image */
    
  }
  
  .two-columnright-column {
    width: 25%; /* Adjust the width as needed */
    margin: 20px;
  }
  
  .two-columnimage-row-image{
    height: 70px;
    margin-top: 70px;
  }
  
  
  .row {
    margin-bottom: 10px;
  }
  
  .fourimage-row{
    background-color: #fff5f5;
   
  }
  .fourimage-rowimage{
    margin-left: 50px;
  }
  
  
  
  .three-column-sectioncolumnimage{
    width: 100%;
    height: 100px;
  }
  
  .three-column-sectioncolumn p{
    text-align: justify;
    
  }
  
  .full-width-paragraph p{
    text-align: center;
    margin: 20px;
  }
  
  .welcomemother-chain-section{
    display: flex;
    margin-top: 20px;
    margin: 20px;
  }
  
  .welcomemother-chainrightcolumn{
    margin-left: 30px;
  }
  .welcomemother-chainrightcolumnrow{
    text-align: left;
    margin: 0;
  }
  
  .welcomemother-chainrightcolumnrow p{
    text-align: left;
    margin: 0;
  }
  
  .welcomemother-chainheading{
    font-size: 2.0rem;
  }
  .addresssection {
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    background-color: rgb(5, 5, 83);
  }
  
  .addresssection .column {
    width: 25%; /* Adjust the width as needed */
    padding: 10px; /* Add padding for spacing */
    margin: 20px;
    background-color: rgb(5, 5, 83);
    margin-left: 20px;
  }
  
  .addresssection .column p {
    text-align: left;
    background-color: rgb(5, 5, 83);
    line-height: 1.5;
  }
  
  .acceptedpayment{
    background-color: #fff5f5;
    margin-top: 1080px;
  }
  .acceptedpayment h1{
    font-size: 2.0rem;
    text-align: left;
    margin: 20px;
  }
  
  .footersection {
    display: flex;
    background-color: rgb(5, 5, 83);
  }
  
  .footersection .column,
  .footersection .footersectioncolumn {
    width: 25%;
    padding: 10px;
    background-color: rgb(5, 5, 83);
    margin: 20px;
    margin-right: 20px; /* Add margin-right for all columns except the last one */
  }
  
  .footersection .column:last-child,
  .footersection .footersectioncolumn:last-child {
    margin-right: 0; /* Remove margin-right for the last column */
  }
  
  .footersection ul {
    list-style-type: none;
    padding: 0;
    margin: 30px; /* Adjust as needed */
    color: #fff5f5;
  }
  
  .footersection ul li {
    margin-left: 1.5em;
    position: relative;
  }
  
  .footersection h3 {
    color: #fff5f5;
  }
  
  .footersection ul li::before {
    content: "•";
    color: #007bff;
    display: inline-block;
    width: 1em;
    margin-left: -2.5em;
    position: absolute;
    left: 0;
  }
  
  a {
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit the color from the parent element */
  }
  
  a:hover {
    text-decoration: none; /* Remove underline on hover */
  }

  @media only screen and (max-width: 768px) {


    
  .signupbanner-content {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;
    width: 100%;
    margin-left: 20px;
    margin-top: -60px;
  }
  .signupbanner-content img{
    width: 60px;
  }
  
  .signupbanner-content h1 {

    font-size: 12px;
  }
  
  .signupbanner-content h2 {
    font-size: 12px;
  }
  

.acceptedpayment{
  background-color: #fff5f5;
}
.acceptedpayment h1{
  font-size: 2.0rem;
  text-align: left;
  margin-top:-400px;
}

  
    
.footersection {
  display: flex;
  background-color: rgb(5, 5, 83);
}

.footersection .column,
.footersection .footersectioncolumn {
  width: 25%;
  padding: 10px;
  background-color: rgb(5, 5, 83);
  margin: 20px;
  margin-right: 20px; /* Add margin-right for all columns except the last one */
}

.footersection .column:last-child,
.footersection .footersectioncolumn:last-child {
  margin-right: 0; /* Remove margin-right for the last column */
}

.footersection ul {
  list-style-type: none;
  padding: 0;
  margin: 30px; /* Adjust as needed */
  color: #fff5f5;
}

.footersection ul li {
  margin-left: 1.5em;
  position: relative;
  font-size: 10px;

}

.footersection h3 {
  color: #fff5f5;
  font-size: 10px;
}

.footersection ul li::before {
  content: "•";
  color: #007bff;
  display: inline-block;
  width: 1em;
  margin-left: -2.5em;
  position: absolute;
  left: 0;
  font-size: 10px;
}

.footersection .support{
  display: none;
}

.footersection .column:last-child,
.footersection .footersectioncolumn:last-child {
  margin-right: 0; /* Remove margin-right for the last column */
  display: none;
}
  }
  
  
  
  