/* MultiStepSignupModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background: rgb(15, 2, 2);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: azure;
  }
  