/* styles.css */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
  }
  
  .wrapper {
    display: flex;
  }
  
  .main-header {
    background-color: #3c8dbc;
    color: #fff;
    padding: 10px;
  }
  
  .sidebar-open .main-sidebar {
    width: 250px;
  }
  
  .main-sidebar {
    background-color: #222d32;
    color: #b8c7ce;
    height: 100vh;
    overflow-y: auto;
  }
  
  .content-wrapper {
    flex: 1;
    padding: 15px;
  }
  
  /* Add more styles as needed */
  