/* components/Navbar.css */
.maindiv{
  background-color: #050000;
}
nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #000000;
    color: white;
  }
  .logo{
    display: flex; /* Add display: flex to the logo container */
    align-items: center; 
  }
  .logo img {
    width: 150px;
    height: 50px;
    margin-right: 0.5rem;
  }
  
  .navbar-links {
    display: flex;
    gap: 1rem;
  }

  .auth-options {
    display: flex;
    gap: 1rem;
  }

  .auth-options a{
    color: white;
    text-decoration: none;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
  }
  
  .hamburger {
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 10px; /* Add the unit (e.g., px) */
  }

  .hamburger:hover + .dropdown {
    display: flex;
    flex-direction: column;
  }
  
/* Show the dropdown when hovering over the parent container */
.navbar:hover .dropdown {
  display: flex;
  flex-direction: column;
}
  
  .dropdown {
    display: none;
    position: absolute;
    top: 10%;
    left: 15%;
    display: flex;
    flex-direction: column;
    background-color: #241616;
    color: white;
    border: 1px solid #fff5f5;
    padding: 0.5rem;
    z-index: 1;
  }
  
  .dropdown a {
    color: white;
    text-decoration: none;
  }

  /* components/Navbar.css */
.banner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-content {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: white;
  width: 100%;
  margin-left: 20px;
  margin-top:-400px;
}

.banner-content h1 {
  font-size: 2.5rem;
}

.banner-content p {
  font-size: 1.2rem;
 
}
.banner-contentnext{
  position: absolute;
  top: 75%;
  left: 20%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: white;
  width: 500px;
  text-align: justify;
}
.banner-contentnext p{
  margin-left: 40px;
}

.banner-contentright{
  position: absolute;
  top: 75%;
  left: 95%;
  transform: translate(-50%, -50%);
  text-align: right;
  color: white;
  width: 500px;
  text-align: justify;
}
.banner-contentright p{
  font-size: 2cqmax;
}

/* components/Navbar.css */

.signup-section {
  top: 10%;
  background-color: #050000;

}

.signup-section h2 {
  color: #333;
}

.signup-buttons {
  display: flex;
  gap: 1rem;
  margin-left: 5%;
  margin-top: 1rem;
  padding: 30px;
}

.signup-button {
  padding: 0.8rem 4rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #faf62a;
  color: rgb(7, 7, 7);
  border: none;
  border-radius: 4px;
}

.google-buttons {
  display: flex;
  gap: 1rem;
  margin-left: 5%;
  padding: 30px;
}
.google-button {
  padding: 0.8rem 3rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
}
.google-button .icon {
  margin-right: 0.5rem;
  color: white; /* Set the color for the icon */
}

.apple-button {
  padding: 0.8rem 3rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
}

.apple-button .icon {
  margin-right: 0.5rem;
  color: white; /* Set the color for the icon */
}

.android-button {
  padding: 0.8rem 3rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
}

.android-icon {
  margin-right: 0.5rem;
  color: white; /* Set the color for the icon */
}

.crypto-business-section {
  padding: 2rem;
  background-color: #000000;
}

.column-container {
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap to the next row */
  justify-content: space-between;
  margin-top: 1rem;
}

.column-first {
  flex: 0 0 calc(20% - 2rem); /* Set width to 20% with some margin between columns */
  margin-right: 0.5rem;
  background-color: #000000;
  padding: 0.5rem;
  border: 1px solid #000000;
  border-radius: 4px;
  color: antiquewhite;
  margin-bottom: 1rem; /* Add some margin at the bottom for spacing */
}

.column {
  flex: 0 0 calc(20% - 2rem); /* Set width to 20% with some margin between columns */
  margin-right: 0.5rem;
  background-color: #000000;
  padding: 0.5rem;
  border: 1px solid #000000;
  border-radius: 4px;
  color: antiquewhite;
  margin-bottom: 1rem; /* Add some margin at the bottom for spacing */
}


/* Responsive styles for the signup section */
@media screen and (max-width: 768px) {
  .signup-section {
    padding: 1rem;
  }

  .signup-buttons {
    flex-direction: column;
  }
}


/* Responsive styles for mobile screens */
@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack items in a column on small screens */
  }

  .navbar-links,
  .auth-options {
    flex-direction: row; /* Display links in a row on larger screens */
  }

  .hamburger {
    margin-left: auto; /* Move the hamburger icon to the right on small screens */
  }

  .dropdown {
    position: static; /* Change position to static on small screens */
    display: none; /* Hide the dropdown by default on small screens */
    width: 100%; /* Full width on small screens */
  }

  .hamburger:hover + .dropdown,
  .navbar:hover .dropdown {
    display: flex; /* Show the dropdown when hovering on small screens */
  }

  /* Additional mobile styles go here */
}

/* Add these styles to your CSS file */
.secondbanner {
  position: relative;
}

.secondbanner img {
  width: 100%; /* Ensure the image takes the full width of the container */
}

/* Add these styles to your CSS file */
.secondbanner-content {
  position: absolute;
  top: 100px;
  left: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  color: rgb(5, 5, 5);
}

.image-row {
  display: flex;
  gap: 20px; /* Adjust the gap as needed */
  margin-bottom: 20px; /* Adjust the margin as needed */
}

.image-row img {
  width: 200px; /* Adjust the width as needed */
  height: auto;
}
.image-container {
  display: flex;
  flex-direction: column;
  margin-right: 20px; /* Adjust the margin as needed to create space between images and paragraph */
}

.right-paragraph{
  margin: 0;
  margin-top: 20px;
  background: rgba(92, 77, 77, 0.5); /* Adjust the RGBA values and transparency as needed */
  padding: 10px; /* Add padding for better visibility */
  color: white; /* Set text color to white */
  font-size: 20chpx; /* Set the font size as needed */

}

.full-width-paragraph {
  background-color: rgb(5, 5, 83); /* Set the background color to blue */
  color: white; /* Set the text color to white */
  padding: 20px; /* Add padding for better visibility */
  text-align: center; /* Center-align the text */
  font-size: 120chpx;
}

.full-width-paragraph h2 {
  margin: 10px 0; /* Add margin between lines */
 
}
.full-width-paragraph h1 {
  margin: 10px 0; /* Add margin between lines */
  font-size: 120chpx;
}

.two-column-section {
  display: flex;
  justify-content: space-between;
  background-color: #fff5f5;
}

.two-columnleft-column p {
  text-align: justify;
  margin: 50px;
  line-height: 2.0;
}

.two-columnleft-column {
  width: 70%; /* Adjust the width as needed */
}

.two-columnimage-row {
  display: flex;
  margin: 40px;
  gap: 10px; /* Adjust the gap between images */
}

.two-columnimage-row img {
  width: 200px; /* Adjust the width of each image */
  
}

.two-columnright-column {
  width: 25%; /* Adjust the width as needed */
  margin: 20px;
}

.two-columnimage-row-image{
  height: 70px;
  margin-top: 70px;
}

.three-column-section {
  display: flex;
  background-color: #fff5f5;
}

.three-column-sectioncolumn {
  flex: 1;
  padding: 20px;
  
  box-sizing: border-box;
}

.row {
  margin-bottom: 10px;
}

.three-column-sectioncolumn img {
  width: 100%;
  height: auto;
}
.three-column-sectioncolumn h1{
  margin-left: 0px;
}
.three-column-sectioncolumn h3{
  margin-left: auto;
}
.three-column-sectioncolumn p {
  text-align: justify;
  line-height: 1.5;
}
.fourimage-row{
  background-color: #fff5f5;
 
}
.fourimage-rowimage{
  margin-left: 50px;
}



.three-column-sectioncolumnimage{
  width: 100%;
  height: 100px;
}

.three-column-sectioncolumn p{
  text-align: justify;
  
}

.process-section{
  display: flex;
  background-color: #fff5f5;
  margin-top: 20px;

}

.process-sectionleftcolumn{
  margin-left: 10px;
  width: 30%;
  background-color: #fff5f5;
}
.process-sectionrightcolumn{
  width: 70%;
}
.process-sectionrightcolumn img{
  width: 100%;
}

.process-sectionleftcolumn button {
  width: 40%;
  height: auto;
  padding: 20px;
  background-color: #faf62a;
  font-size: 15px;
  margin-bottom: 10px;
}

.full-width-paragraph p{
  text-align: center;
  margin: 20px;
}

.welcomemother-chain-section{
  display: flex;
  margin-top: 20px;
  margin: 20px;
}

.welcomemother-chainrightcolumn{
  margin-left: 30px;
}
.welcomemother-chainrightcolumnrow{
  text-align: left;
  margin: 0;
}

.welcomemother-chainrightcolumnrow p{
  text-align: left;
  margin: 0;
}

.welcomemother-chainheading{
  font-size: 2.0rem;
}
.addresssection {
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(5, 5, 83);
}

.addresssection .column {
  width: 25%; /* Adjust the width as needed */
  padding: 10px; /* Add padding for spacing */
  margin: 20px;
  background-color: rgb(5, 5, 83);
  margin-left: 20px;
}

.addresssection .column p {
  text-align: left;
  background-color: rgb(5, 5, 83);
  line-height: 1.5;
}

.acceptedpayment{
  background-color: #fff5f5;
}
.acceptedpayment h1{
  font-size: 2.0rem;
  text-align: left;
  margin: 20px;
}

.footersection {
  display: flex;
  background-color: rgb(5, 5, 83);
}

.footersection .column,
.footersection .footersectioncolumn {
  width: 25%;
  padding: 10px;
  background-color: rgb(5, 5, 83);
  margin: 20px;
  margin-right: 20px; /* Add margin-right for all columns except the last one */
}

.footersection .column:last-child,
.footersection .footersectioncolumn:last-child {
  margin-right: 0; /* Remove margin-right for the last column */
}

.footersection ul {
  list-style-type: none;
  padding: 0;
  margin: 30px; /* Adjust as needed */
  color: #fff5f5;
}

.footersection ul li {
  margin-left: 1.5em;
  position: relative;
}

.footersection h3 {
  color: #fff5f5;
}

.footersection ul li::before {
  content: "•";
  color: #007bff;
  display: inline-block;
  width: 1em;
  margin-left: -2.5em;
  position: absolute;
  left: 0;
}

a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit the color from the parent element */
}

a:hover {
  text-decoration: none; /* Remove underline on hover */
}

.white-label {
  color: white;
}


/* Default styles for larger screens */

@media only screen and (max-width: 768px) {
  /* Styles for screens up to 768px wide (e.g., tablets and mobiles) */
  /* Add your responsive styles here */
  .maindiv{
    width: 100%;
  }

  .navbar-links {
   
    gap: 0.5rem;
    font-size: 0.6rem;
    margin: 10px;
  }

  .auth-options{
    font-size: 0.7rem;
  }
  /* components/Navbar.css */
  .banner {
    /* Adjust the properties for smaller screens */
    height: 250px; /* Example height, adjust as needed */
    width: 100%;
    object-fit: cover;
    background-image: url('../assets/gifbanner.gif');

    /* Add any other styles for smaller screens */
  }
  .banner-video{
    height:80%;
		top:0;
		left:0;
		width:40%;
		position:absolute;
  }
  .banner-content{
    margin-top: -97px;
  }
  .banner-content p{
   
    font-size: 16px;
  }
  .bannerparagraphnext{
    display: none;
  }
  .banner-contentright{
    display: none;
  }

  .banner-content span{
    margin-top: -60px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .decentralized{
    font-size: 6px;
  }


  .banner-contentnext p{
    font-size: 0.8rem;
    margin-left: 200px;
    margin-top: 5%;
  }


  
.google-buttons {
  display: flex;
  gap: -5rem;
  margin-left: 1%;
  padding: 1px;
}
.google-button {
  padding: 0.5rem;
  font-size: 0.6rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
  height: 60px;
}
.google-button .icon {
  margin-right: 0.1rem;
  color: white; /* Set the color for the icon */
}

.apple-button {
  padding: 0.5rem;
  font-size: 0.6rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
  height: 60px;
}

.apple-button .icon {
  margin-right: 0.5rem;
  color: white; /* Set the color for the icon */
}

.android-button {
  padding: 0.5rem;
  font-size: 0.6rem;
  cursor: pointer;
  background-color: #7c7c71;
  color: rgb(248, 241, 241);
  border: none;
  border-radius: 4px;
  height: 60px;
}

.android-icon {
  margin-right: 0.5rem;
  color: white; /* Set the color for the icon */
}


/* Add these styles to your CSS file */
.secondbanner-content {
  position: absolute;
  top: 2px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  color: rgb(5, 5, 5);
}

.image-row {
  display: flex;
  gap: 5px; /* Adjust the gap as needed */
}

.image-row img {
  width: 65px; /* Adjust the width as needed */
  height: 35px;
}

.image-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px; /* Adjust the margin as needed to create space between images and paragraph */
}

.right-paragraph{
display: none;

}

.full-width-paragraph {
  background-color: rgb(5, 5, 83); /* Set the background color to blue */
  color: white; /* Set the text color to white */
  padding: 20px; /* Add padding for better visibility */
  text-align: center; /* Center-align the text */
  font-size: 120chpx;
}

.full-width-paragraph h2 {
  margin: 10px 0; /* Add margin between lines */
  font-size: 14px;

 
}
.full-width-paragraph h1 {
  margin: 10px 0; /* Add margin between lines */
  font-size: 14px;
}
.two-columnleft-column{
  display: none;
}

.three-column-section{
  display: none;
}

.process-section{
  display: flex;
  background-color: #fff5f5;
  margin-top: 20px;

}

.process-sectionleftcolumn{
  margin-left: 10px;
  width: 100%;
  background-color: #fff5f5;
}
.process-sectionrightcolumn{
  width: 0%;
  display: none;
  
}
.process-sectionrightcolumn img{
 
  display: none;
  margin-top: 200px;
}

.process-sectionleftcolumn button {
  width: 40%;
  height: auto;
  padding: 20px;
  background-color: #faf62a;
  font-size: 15px;
  margin-bottom: 10px;
}

.banner .motherworldchain{
  width: 97%;
  object-fit: fill;
}
.banner .swapservice{
  object-fit: fill;
}

.addresssection h3{
  font-size: 15px;
}

.addresssection p{
  font-size: 6px;
}
.banner .krakens{
  height: 260px;
  width: 600px;
  object-fit: fill;
}



.footersection {
  display: flex;
  background-color: rgb(5, 5, 83);
}

.footersection .column,
.footersection .footersectioncolumn {
  width: 25%;
  padding: 10px;
  background-color: rgb(5, 5, 83);
  margin: 20px;
  margin-right: 20px; /* Add margin-right for all columns except the last one */
}

.footersection .column:last-child,
.footersection .footersectioncolumn:last-child {
  margin-right: 0; /* Remove margin-right for the last column */
}

.footersection ul {
  list-style-type: none;
  padding: 0;
  margin: 30px; /* Adjust as needed */
  color: #fff5f5;
}

.footersection ul li {
  margin-left: 1.5em;
  position: relative;
}

.footersection h3 {
  color: #fff5f5;
}

.footersection ul li::before {
  content: "•";
  color: #007bff;
  display: inline-block;
  width: 1em;
  margin-left: -2.5em;
  position: absolute;
  left: 0;
}
}




